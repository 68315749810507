export const photos = [
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637773/cons/IMG_0267_zzpc5r.jpg",
		width: 3,
		height: 4,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637813/cons/IMG_9001_xsgsrk.jpg",
		width: 3,
		height: 4,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637810/cons/IMG_8995_vdroip.jpg",
		width: 4,
		height: 3,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637827/cons/IMG_9026_ofcvxn.jpg",
		width: 3,
		height: 4,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637829/cons/IMG_9028_e59mv2.jpg",
		width: 3,
		height: 4,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637824/cons/IMG_9008_oqnywc.jpg",
		width: 3,
		height: 4,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637815/cons/IMG_8996_xj9jza.jpg",
		width: 4,
		height: 3,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637792/cons/IMG_8968_dlugtb.jpg",
		width: 4,
		height: 3,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://res.cloudinary.com/dvbdefnwx/image/upload/v1666637785/cons/IMG_8959_mfcudc.jpg",
		width: 4,
		height: 3,
		alt: "people playing Moratia Card Quest Games",
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/moratia-games.appspot.com/o/event_images%2F2022%2Fdemo%2FIMG_8965.jpeg?alt=media&token=2a3b498a-438d-454b-9a0a-26e56b37f101",
		width: 4,
		height: 3,
		alt: "Tom Royalty playing Moratia Card Quest game with woman at GenCon",
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/moratia-games.appspot.com/o/event_images%2F2022%2Fdemo%2FIMG_8972.jpeg?alt=media&token=4f9e006f-67dd-4fe8-ab65-f38acb561d7f",
		width: 3,
		height: 4,
		alt: "Jande and Tom Royalty in Moratia Games shirt with woman",
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/moratia-games.appspot.com/o/event_images%2F2022%2Fdemo%2FIMG_0284.jpeg?alt=media&token=e2621ee9-d495-4328-b666-2cacde85ccba",
		width: 3,
		height: 4,
		alt: "Tom Royalty playing Moratia Card Quest Game with man at table",
	},
	{
		src: "https://firebasestorage.googleapis.com/v0/b/moratia-games.appspot.com/o/event_images%2F2022%2Fdemo%2FIMG_0285.jpeg?alt=media&token=d68fe5f5-9169-40eb-8a2b-b8f2651e725a",
		width: 3,
		height: 4,
		alt: "Tom Royalty playing Moratia Card Quest Game with a group of men and women",
	},
];
